<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-select v-model="tenantCrop" filterable placeholder="公司" clearable class="searchParam" size="medium">
            <el-option
                v-for="item in tenantCropArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="operationType" placeholder="操作类型" clearable>
            <el-option label="存入" value="1"/>
            <el-option label="支出" value="2"/>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="moneyType" placeholder="充值类型" clearable>
            <el-option label="押金" value="1"/>
            <el-option label="订货预付款" value="2"/>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 10px;padding-left: 10px">
      <el-table
          :data="table.tenantList"
          :row-style="{height:30+'px'}"
          :cell-style="{padding:5+'px'}"
          :stripe="true"
          border
          height="650"
          max-height="650"
          :default-sort="{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column prop="tenantName" label="公司名称" align="center"></el-table-column>
        <el-table-column prop="operationDate" label="动账时间" align="center"></el-table-column>
        <el-table-column prop="operationEmpName" label="缴费人" align="center"></el-table-column>
        <el-table-column  label="操作类型" align="center" >
          <template slot-scope="scope">
            <span v-if="scope.row.operationType === 1" style="color: red">
              存入
            </span>
            <span v-else style="color: green">
              支出
            </span>
          </template>
        </el-table-column>
        <el-table-column  label="金额类型" align="center" >
          <template slot-scope="scope">
            <span>
              {{scope.row.moneyType === 1 ? "押金" : "订货预付款" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="money" label="充值金额" align="center"></el-table-column>
        <el-table-column  label="当次充值后余额" align="center" >
          <template slot-scope="scope">
              当次{{scope.row.moneyType === 1 ? "押金" : "订货预付款" }}余额 {{scope.row.balance}}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center"></el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>

export default {
  name: "tenant-bill",
  data() {
    return {
      tenantCrop: "",
      operationType: "",
      moneyType: "",
      table: {
        tenantList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      tenantCropArray:[],
    }
  },
  created() {
    this.queryTenantBillList();
    this.queryTenantIdArray()
  },
  methods: {
    search() {
      this.table.page = 1;
      this.queryTenantBillList();
    },
    //查询客户列表
    queryTenantBillList: function () {
      this.$axios({
        method: "GET",
        url: "/tenantBill/queryList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          tenantCrop: this.tenantCrop,
          operationType: this.operationType,
          moneyType: this.moneyType,
        }
      }).then(response => {
        this.table.total = response.data.data.total;
        this.table.tenantList = response.data.data.list;
      })
    },
    //查询公司ID
    queryTenantIdArray(){
      this.$selectUtils.queryTenantCrops().then(response=>{
        this.tenantCropArray=JSON.parse(response.data.data);
      })
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryTenantBillList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryTenantBillList()
    },
  },
}
</script>

<style scoped>

</style>